import React, { useEffect, useState } from "react";

import { AppBar, Toolbar } from "@material-ui/core";

import logo from "../../assets/svg/night/logo.svg";

import logo_coop from "../../assets/svg/logo_coop.svg";

import AdminControls from './AdminControls';

import AppVersion from "./AppVersion";

import End from "./End";

import { useExperience } from "../contexts/experience";

export default function Header() {

  const { end } = useExperience();

  const [showAdmin, setShowAdmin] = useState(false);

  const [showEnd, setShowEnd] = useState(false);

  useEffect(() => {
    if (end) {
      setShowEnd(true);
    }
  }, [end]);

  return (
    <AppBar position="static">
      <Toolbar style={toolbar}>

        <div onClick={() => setShowAdmin(true)} style={{ display: 'flex' }}>
          <img alt="Itinera" src={logo} width={"20%"} />
        </div>

        <AppVersion />
        <img alt="COOP Itinera" src={logo_coop} width={"30%"} />
      </Toolbar>

      <AdminControls show={showAdmin} onClose={() => setShowAdmin(false)} />

      <End show={showEnd} />

    </AppBar>
  );
}

const toolbar = {
  justifyContent: "space-between",
  paddingTop: "10px",
  paddingBottom: "10px",
};
