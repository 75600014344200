import audio0url from '../../assets/audio/music/00.mp3';
import audio1url from '../../assets/audio/music/01.mp3';
import audio2url from '../../assets/audio/music/02.mp3';
import audio3url from '../../assets/audio/music/03.mp3';
import audio4url from '../../assets/audio/music/04.mp3';
import audio5url from '../../assets/audio/music/05.mp3';
import audio6url from '../../assets/audio/music/06.mp3';
import audio7url from '../../assets/audio/music/07.mp3';
import audio8url from '../../assets/audio/music/08.mp3';
import audio9url from '../../assets/audio/music/09.mp3';
import audio10url from '../../assets/audio/music/10.mp3';
import audio11url from '../../assets/audio/music/11.mp3';
import audio12url from '../../assets/audio/music/12.mp3';

// punto 0 finocchietti
// import audio0_b_url from '../../assets/audio/music/Punto_00_Finocchietti.mp3';
// // punto 04 finocchietti è un finale alternativo (quartiere venezia seconda parte)
// import audio4_b_url from '../../assets/audio/music/Punto_04_Finocchietti.mp3';
// // punto 12 finocchietti è una variante di Cantina Canottieri e Palio
// import audio12_b_url from '../../assets/audio/music/Punto_12_Finocchietti.mp3';



const music = {
  audio0: {
    url: audio0url,
    label: 'Darsena Nuova'
  },
  audio1: {
    url: audio1url,
    label: 'Porto Mediceo'
  },
  audio2: {
    url: audio2url,
    label: 'Fortezza Vecchia e Darsena Vecchia'
  },
  audio3: {
    url: audio3url,
    label: 'Monumento 4 Mori e Fortezza Vecchia'
  },
  audio4: {
    url: audio4url,
    label: 'Quartiere Venezia'
  },
  audio5: {
    url: audio5url,
    label: 'Scali Rosciano'
  },
  audio6: {
    url: audio6url,
    label: 'Chiesa e Convento dei Domenicani e Fortezza Nuova'
  },
  audio7: {
    url: audio7url,
    label: 'Il Pontino'
  },
  audio8: {
    url: audio8url,
    label: 'Piazza della Repubblica Parte 1"'
  },
  audio9: {
    url: audio9url,
    label: 'Mercato Centrale'
  },
  audio10: {
    url: audio10url,
    label: 'Chiesa degli Olandesi'
  },
  audio11: {
    url: audio11url,
    label: "I Palazzi dell'800"
  },
  audio12: {
    url: audio12url,
    label: 'Cantina Canottieri Livornesi e Palio Marinaro'
  },
  audio0_b: {
    url: audio0url,
    label: 'Benvenuti e Venezia'
  },
  audio4_b: {
    url: audio12url, // here is inverted with 12
    label: 'Quartiere Venezia II Parte'
  },
  audio12_b: {
    url: audio12url, // here is inverted with 4
    label: 'Cantina Canottieri Livornesi e Palio Marinaro'
  }
};

export default music;