const experience = {
  patterns: [
    {
      id: "exp1music",
      label: "Pattern default Fossi Livorno Musica",
      overlap: true,
      spots: [
        {
          id: "s0a",
          label: "Darsena Nuova",
          position: "p0a",
          audio: "a0a",
          notAfter: "s0b",
          vocalGuideDuration: 41
        },
        {
          id: "s01",
          label: "Porto Mediceo",
          position: "p01",
          audio: "a01",
          vocalGuideDuration: 191
        },
        {
          id: "s02",
          label: "Fortezza Vecchia, Darsena Vecchia",
          position: "p02",
          audio: "a02",
          vocalGuideDuration: 82
        },
        {
          id: "s03",
          label: "Monumento 4 Mori, Fortezza Vecchia",
          position: "p03",
          audio: "a03",
          vocalGuideDuration: 156
        },
        {
          id: "s0b",
          label: "Benvenuti",
          position: "p0b",
          audio: "a0b",
          notAfter: "s0a",
          vocalGuideDuration: 128
        },
        {
          id: "s04",
          label: "Quartiere Venezia",
          position: "p04",
          audio: "a04",
          notAfter: "s0b",
          vocalGuideDuration: 108
        },
        {
          id: "s04b",
          label: "Quartiere Venezia - Parte 2",
          position: "p04b",
          audio: "a04b",
          notAfter: "s0a",
          vocalGuideDuration: 105
        },
        {
          id: "s05",
          label: "Scali Rosciano",
          position: "p05",
          audio: "a05",
          vocalGuideDuration: 104
        },
        {
          id: "s06",
          label: "Chiesa e Convento dei Domenicani, Fortezza Nuova",
          position: "p06",
          audio: "a06",
          vocalGuideDuration: 111
        },
        {
          id: "s07",
          label: "Il Pontino",
          position: "p07",
          audio: "a07",
          vocalGuideDuration: 162
        },
        {
          id: "s08",
          label: "Piazza della Repubblica",
          position: "p08",
          audio: "a08",
          vocalGuideDuration: 241
        },
        {
          id: "s09",
          label: "Mercato Centrale",
          position: "p09",
          audio: "a09",
          vocalGuideDuration: 160
        },
        {
          id: "s10",
          label: "Chiesa degli Olandesi",
          position: "p10",
          audio: "a10",
          vocalGuideDuration: 121
        },
        {
          id: "s11",
          label: "I Palazzi dell'800",
          position: "p11",
          audio: "a11",
          vocalGuideDuration: 95
        },
        {
          id: "s12",
          label: "Cantina Canottieri Livornesi, Palio Marinaro",
          position: "p12",
          audio: "a12",
          notAfter: "s0b",
          vocalGuideDuration: 83
        },
        {
          id: "s12b",
          label: "Cantina Canottieri Livornesi, Palio Marinaro",
          position: "p12",
          audio: "a12b",
          notAfter: "s0a",
          vocalGuideDuration: 108
        }
      ],
    },
  ],
  options: {
    cookies: true
  }
};

export default experience;
