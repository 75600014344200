const geo = {
  positions: [
    // {
    //   id: "pWelcomeA",
    //   label: "Benvenuti",
    //   lon: 10.303008,
    //   lat: 43.545286,
    //   radius: 90,
    //   deadband: 10,
    // },
    {
      id: "p0a",
      label: "Darsena Nuova",
      lon: 10.302673,
      lat: 43.546584,
      radius: 50,
      deadband: 100,
    },
    {
      id: "p01",
      label: "Porto Mediceo",
      lon: 10.300235,
      lat: 43.547615,
      radius: 80,
      deadband: 330,
    },
    {
      id: "p02",
      label: "Fortezza Vecchia e Darsena Vecchia",
      lon: 10.302153,
      lat: 43.551338,
      radius: 40,
      deadband: 130,
    },
    {
      id: "p03",
      label: "Monumento 4 Mori e Fortezza Vecchia",
      lon: 10.303764,
      lat: 43.549586,
      radius: 65,
      deadband: 255,
    },
    {
      id: "p04",
      label: "Quartiere Venezia II Parte",
      lon: 10.303764,
      lat: 43.552719,
      radius: 35,
      deadband: 200,
    },
    {
      id: "p04b",
      label: "Quartiere Venezia II Parte",
      lon: 10.303764,
      lat: 43.552719,
      radius: 35,
      deadband: 450,
    },
    {
      id: "p0b",
      label: "Benvenuti e Venezia",
      lon: 10.307494,
      lat: 43.553084,
      radius: 60,
      deadband: 10
    },
    {
      id: "p05",
      label: "Scali Rosciano",
      lon: 10.306709,
      lat: 43.553597,
      radius: 35,
      deadband: 100,
    },
    {
      id: "p06",
      label: "Chiesa e Convento dei Domenicani e Fortezza Nuova",
      lon: 10.308133,
      lat: 43.554586,
      radius: 35,
      deadband: 140,
    },
    {
      id: "p07",
      label: "Il Pontino",
      lon: 10.310403,
      lat: 43.555447,
      radius: 40,
      deadband: 255,
    },
    {
      id: "p08",
      label: "Piazza della Repubblica",
      lon: 10.313111,
      lat: 43.553422,
      radius: 35,
      deadband: 275,
    },
    {
      id: "p09",
      label: "Mercato Centrale",
      lon: 10.314301,
      lat: 43.550627,
      radius: 32,
      deadband: 175,
    },
    {
      id: "p10",
      label: "Chiesa degli Olandesi",
      lon: 10.312527,
      lat: 43.548934,
      radius: 35,
      deadband: 200,
    },
    // {
    //   id: "p10b",
    //   label: "Chiesa degli Olandesi wider",
    //   lon: 10.312527,
    //   lat: 43.548934,
    //   radius: 35,
    //   deadband: 525,
    // },
    {
      id: "p11",
      label: "I Palazzi dell'800",
      lon: 10.310240,
      lat: 43.547415,
      radius: 35,
      deadband: 320,
    },
    {
      id: "p12",
      label: "Cantina Canottieri Livornesi e Palio Marinaro",
      lon: 10.305466,
      lat: 43.547520,
      radius: 33,
      deadband: 315
    }
  ],
  options: {
    enableHighAccuracy: true,
    accuracy: 50,
    defaultDeadband: 500,
    defaultRadius: 20,
    defaultFetch: 1,
  }
};

export default geo;
