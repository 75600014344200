import React from "react";

import { Box, Dialog, Typography, Button } from "@material-ui/core";

import Layout from "./Layout";

import Logo from "./Logo";

import String from "./String";

import { useTheme } from "../contexts/theme";

import { useExperience } from "../contexts/experience";

export default function End({ show }) {

  const { theme, styles } = useTheme();

  const exp = useExperience();

  const hardReset = () => {
    exp.destroy();
    window.location = "/";
  };

  const border = `1px solid ${styles.palette.background.contrast}14`;

  return (
    <Dialog
      open={show}
      className={"lock " + theme}
      PaperProps={{
        style: {
          border,
          boxShadow: "0 3px 6px rgb(0 0 0 / 11%), 0 3px 6px rgb(0 0 0 / 16%)"
        }
      }}
    >
      <Layout
        top={
          <Box mt={7} textAlign="center">
            <Logo />
          </Box>
        }
      >
        <Box
          textAlign="center"
          style={{ position: 'relative' }}
        >
          <Typography variant="title" mb={2}>
            <String s="end_title" />
          </Typography>
          <Typography variant="subtitle1" mb={2}>
            <String s="end_message" />
          </Typography>

          <Box mb={2}>
            <Button
              color={theme === 'night' ? 'primary' : 'secondary'}
              variant="contained"
              fullWidth
              onClick={hardReset}
            >
              <String s="end_reset_hard" />
            </Button>
          </Box>

        </Box>
      </Layout>
    </Dialog>
  );
}