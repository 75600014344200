import React, { useState, useEffect, useRef } from "react";

import GeoXp from "@mezzo-forte/geoxp";

import geo from "../../config/geo";

import audio from "../../config/audio";

import experience from "../../config/experience";

import { getCookie } from "../utils/cookies";

import { useLanguage } from "./language";

const PATTERN_ID = 'exp1music';

const TOUR_END_SPOTS = ['s12', 's04b'];

const ExperienceContext = React.createContext(null);

const config = { geo, audio, experience };

const downloadConfig = () => {
  const jsonConfig = JSON.stringify(config);
  const blob = new Blob([jsonConfig], { type: "text/json" });
  const link = document.createElement("a");
  link.download = 'fossiMusicali-geoXpConfig.json';
  link.href = window.URL.createObjectURL(blob);
  link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");
  const evt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  link.dispatchEvent(evt);
  link.remove();
};

window.downloadConfig = downloadConfig;

const geoXp = new GeoXp(config);

window.geoXp = geoXp;

const simulateSpot = (spotId) => {
  const spot = experience.patterns[0].spots.find((el) => el.id === spotId);
  if (!spot) {
    console.warn("spot not found", spotId);
  }

  const posId = spot.position;

  const position = geo.positions.find((pos) => pos.id === posId);
  if (!position) {
    console.warn("position not found", posId);
  }

  console.log("simultaing spot", spot, "with position", position);

  geoXp.updateGeolocation({
    coords: {
      latitude: position.lat,
      longitude: position.lon,
      accuracy: 10,
    },
  });
};

window.simulateSpot = simulateSpot;

export default function Experience({ children }) {

  const onInit = useOnInit();

  const { language } = useLanguage();

  const [position, setPosition] = useState();

  const [active, setActive] = useState();

  const [player, setPlayer] = useState();

  const [spotIndex, setSpotIndex] = useState(0);

  const [end, setEnd] = useState(false);

  const reload = (_config) => geoXp.reload(_config);

  const destroy = () => geoXp.destroy();

  const getGeoXpCookie = (patternName) => getCookie(`geoxp-pattern-${patternName}`);

  useEffect(() => {
    geoXp.on("position", res => {
      // console.log('pos', res);
      setPosition(res);
    });

    geoXp.on("active", res => {
      // console.log('active', res);
    });

    geoXp.on("play", res => {
      // console.log('play', res);
      setPlayer(res);
      setActive(res.spot);

      if (TOUR_END_SPOTS.includes(res.spot.id)) {

        const lastAudioDuration = res.audio._duration; // seconds

        // show panel 300 sec before last piece ends
        // if music is shorter than 300 sec, shoiw after 30 sec from start
        const showEndPanelAfter = Math.max(lastAudioDuration - 300, 30); //seconds

        setTimeout(() => {
          setEnd(true);
        }, showEndPanelAfter * 1000); // milliseconds

      }
    });

    geoXp.on("stop", res => {
      // console.log('stop', res);
      if (geoXp.hasAudioPlaying()) {
        return;
      }
      // console.log('stop, no audio playing');
      setActive(null);
      setPlayer(null);
    });

    // prev played spots (cookies)
    const geoXpCookie = getGeoXpCookie(PATTERN_ID);
    if (geoXpCookie) {
      const visitedSpots = geoXp.getVisitedSpots(PATTERN_ID);
      const visitedSpotCount = (visitedSpots && Array.isArray(visitedSpots)) ? visitedSpots.length : 0;
      setSpotIndex(i => i + visitedSpotCount);
    }

    return () => geoXp.destroy();
  }, []);

  useEffect(() => {
    if (!onInit) {
      const _config = { ...config, audio };
      reload(_config);
    }
  }, [language, onInit]);

  const spots = geo.positions;

  const playing = geoXp.hasAudioPlaying()

  useEffect(() => {
    if (active) {
      setSpotIndex((i) => i + 1);
    }
  }, [active]);

  return (
    <ExperienceContext.Provider
      value={{
        spots,
        spotIndex,
        position,
        active,
        player,
        end,
        playing,
        getGeoXpCookie,
        reload,
        destroy
      }}
    >
      {children}
    </ExperienceContext.Provider>
  );
}

export const useExperience = () => React.useContext(ExperienceContext);

const useOnInit = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};
