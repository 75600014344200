import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";

import { useExperience } from "../contexts/experience";
import palette from "../../config/themes/palette";

export default function Player({ spot }) {
  const [progress, setProgress] = useState(0);
  const { player, active, spotIndex } = useExperience();

  const audio = player?.audio;
  const duration = (active?.vocalGuideDuration || audio?._duration || 1) + 5;

  const perc = (progress / duration) * 100;
  //const elapsed = formatTimer(progress);
  const countdown = formatTimer(duration - progress);

  useEffect(() => {
    const seek = setInterval(() => {
      const _p = audio ? audio.seek() : 0;
      setProgress(_p);
    }, 100);
    return () => clearInterval(seek);
  }, [audio]);

  // const skip = (s) => {
  //   const _s = progress + s;
  //   if (_s > 0 && _s < duration) audio?.seek(_s);
  // };

  return (
    <Card style={{ textAlign: "center" }}>
      <Box mt={3} mb={2}>
        <Avatar style={labelStyle}>{spotIndex || 1}</Avatar>
        <Typography variant="h5" mt={1}>
          {spot ? spot.label : ''}
        </Typography>
      </Box>
      <Box mb={3}>
        <Grid
          container
          justify="space-around"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            <IconButton
              style={btnStyle}
            >
              <Box style={countDownStyle}>
                <Typography fontSize={"5vw"} fontWeight="bold">
                  {countdown.negativeTime ? '' : '-'} {countdown.mm}:{countdown.ss}
                </Typography>
              </Box>
              <CircularProgress
                variant="determinate"
                value={Math.floor(perc)}
                style={progressStyle}
                thickness={2}
              />
            </IconButton>
            {/* <Typography fontSize={"3vw"} color="textSecondary" mt={1}>
              {elapsed.mm}:{elapsed.ss}
            </Typography> */}
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

const formatTimer = (t) => {

  const tModule = Math.abs(t);

  const o = new Date(0);
  const p = new Date(tModule * 1000);
  const array = new Date(p.getTime() - o.getTime())
    .toISOString()
    .split('T')[1]
    .split('Z')[0]
    .split(':');

  return {
    negativeTime: (t < 0),
    mm: array[1],
    ss: array[2].split('.')[0]
  };
};

const btnStyle = {
  position: "relative",
  border: "2px solid",
  height: "22vw",
  width: "22vw",
};
const countDownStyle = {
  position: "absolute",
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
};
const progressStyle = { position: "absolute", height: "107%", width: "107%" };
const labelStyle = {
  background: palette.red,
  height: "20px",
  width: "20px",
  margin: "auto",
  fontSize: "12px",
  fontWeight: "bold",
};
