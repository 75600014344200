import packageJson from "../../../package.json";

const VERSION = packageJson.version;

const divStyle = {
  position: "absolute",
  top: 5,
  left: "calc(50% - 20px)",
  opacity: .8,
  width: 40
};

export default function AppVersion() {
  return (
    <div style={divStyle} className="appversion">
      <small>
        <em>v{VERSION}</em>
      </small>
    </div>
  );
}
