import React from 'react'

import "./Soundwave.css";

function Soundwave() {
  return (
    <div className="soundwave-container">
      <svg xmlns="http://www.w3.org/2000/svg" width="200" height="229" viewBox="0 0 200 229">
        <g className="soundform-svg">
          <g className="soundform-svg-group" data-name="Group 2329" transform="translate(-182.5 -435.5)">
            <line className="soundform-line" id="Line_716" data-name="Line 716" y1="71.478" transform="translate(184.5 511.099)" fill="none" stroke="#a5a093" strokeWidth="3" />
            <line className="soundform-line" id="Line_714" data-name="Line 714" y1="191.854" transform="translate(209.5 449.504)" fill="none" stroke="#a5a093" strokeWidth="3" />
            <line className="soundform-line" id="Line_718" data-name="Line 718" y1="228.857" transform="translate(235.5 435.5)" fill="none" stroke="#a5a093" strokeWidth="3" />
            <line className="soundform-line" id="Line_720" data-name="Line 720" y1="212.313" transform="translate(261.5 442.188)" fill="none" stroke="#a5a093" strokeWidth="3" />
            <line className="soundform-line" id="Line_722" data-name="Line 722" y1="115.48" transform="translate(286.5 472.391)" fill="none" stroke="#a5a093" strokeWidth="3" />
            <line className="soundform-line" id="Line_717" data-name="Line 717" y1="177.857" transform="translate(312.5 450.5)" fill="none" stroke="#a5a093" strokeWidth="3" />
            <line className="soundform-line" id="Line_715" data-name="Line 715" y1="191.854" transform="translate(338.5 449.504)" fill="none" stroke="#a5a093" strokeWidth="3" />
            <line className="soundform-line" id="Line_719" data-name="Line 719" y1="228.857" transform="translate(364.5 435.5)" fill="none" stroke="#a5a093" strokeWidth="3" />
            <line className="soundform-line" id="Line_721" data-name="Line 721" y1="212.313" transform="translate(389.5 442.188)" fill="none" stroke="#a5a093" strokeWidth="3" />
            <line className="soundform-line" id="Line_724" data-name="Line 724" y1="115.48" transform="translate(415.5 472.391)" fill="none" stroke="#a5a093" strokeWidth="3" />
            <line className="soundform-line" id="Line_725" data-name="Line 725" y1="177" transform="translate(441.5 450.5)" fill="none" stroke="#a5a093" strokeWidth="3" />
            <line className="soundform-line" id="Line_723" data-name="Line 723" y1="152" transform="translate(466.5 468.736)" fill="none" stroke="#a5a093" strokeWidth="3" />
          </g>
        </g>
      </svg>
    </div>
  )
};

export default Soundwave;
