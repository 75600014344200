import React, { useState, useCallback } from "react";

import Hammer from "react-hammerjs";

import { Dialog, Fab, Typography, Stack } from "@material-ui/core";

import LockIcon from "@material-ui/icons/Lock";

import ArrowUpIcon from '@material-ui/icons/ArrowUpwardOutlined';

import Layout from "./Layout";

import String from "./String";

import { useTheme } from "../contexts/theme";

import IdleTimer from 'react-idle-timer';

const AUTOLOCK_TIMEOUT = 8 * 1000; // ms

const SWIPE_THRESHOLD = 100; // px

const LOCK_BACKDROP_TRANSPARENCY = .35;

export default function Lock() {

  const [locked, setLocked] = useState(false);

  const handleOnIdle = useCallback(() => {
    if (!locked) {
      setLocked(true);
    }
  }, []);

  return (
    <>
      <IdleTimer timeout={AUTOLOCK_TIMEOUT} onIdle={handleOnIdle} debounce={500} />

      <Fab
        onClick={() => setLocked(true)}
        style={{
          position: "fixed",
          bottom: "30px",
          right: "30px",
          zIndex: 9,
        }}
      >
        <LockIcon />
      </Fab>

      <LockedScreen locked={locked} setLocked={setLocked} />
    </>
  );
}

function LockedScreen({ locked, setLocked }) {

  const { theme, styles } = useTheme();

  const dialogBackdropBgColor = `rgba(${styles.palette.background.rgb}, ${LOCK_BACKDROP_TRANSPARENCY})`;

  return (
    <Dialog
      fullScreen
      open={locked}
      className={`lock ${theme}`}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          transition: 'background-color .5s ease-in-out'
        }
      }}
      BackdropProps={{
        style: {
          backgroundColor: dialogBackdropBgColor
        }
      }}
    >
      <SwipeUp setLocked={setLocked} />
    </Dialog>
  );
}

function SwipeUp({ setLocked }) {

  const { styles } = useTheme();

  const handleSwipe = useCallback(() => setLocked(false), []);

  return (
    <Layout
      full
      top={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
          sx={{
            color: styles.palette.text.unlockInstructions,
            background: styles.palette.background.unlockInstructions
          }}
        >
          <LockIcon />
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "800",
              padding: "1.5rem 0",
            }}>
            <String s="screen_locked" />
          </Typography>
        </Stack>
      }
      bottom={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
          sx={{
            color: styles.palette.text.unlockInstructions,
            background: styles.palette.background.unlockInstructions
          }}
        >
          <ArrowUpIcon />
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "800",
              padding: "1rem 0 .5rem",
            }}>
            <String s="swipe_up" />
          </Typography>

        </Stack>
      }>
      <Hammer
        onSwipe={handleSwipe}
        direction="DIRECTION_UP"
        options={{
          recognizers: {
            swipe: {
              threshold: SWIPE_THRESHOLD
            }
          }
        }}
      >
        <div id="swipe-area"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
          }} />
      </Hammer>
    </Layout>
  );
}