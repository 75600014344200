const it = {
  lang: "Italiano",
  select_lang: "Scegli la lingua",
  day: "Giorno",
  night: "Notte",
  confirm: "Conferma",
  continue: "Continua",
  yes_continue: "Si, continua",
  start: "SUONA",
  back: "Indietro",

  headset: "Indossa le cuffie in dotazione per sentire l'audio correttamente",
  raise_volume: "Aumenta il volume dello smartphone",
  sound: "Stai sentendo l'audio correttamente?",
  livorno: '"L" come Livorno',
  unlocking:
    "Durante la visita, se devi usare l'app, sbloccala con questa sequenza",
  unlock: 'Sblocca con il segno "L"',
  areyouready: "La colonna sonora dei Fossi",
  welcome: "BENVENUTO",
  beforestart:
    "Cicca su SUONA per iniziare riprodurre la musica di benvenuto. Gli altri brani inizieranno automaticamente in base alla posizione della barca.",
  map: "Mappa",
  code: "FAQ",
  insert_code:
    "Inserisci il numero corrispondente alle TAPPE DEL VIAGGIO che trovi sul depliant",
  faq: [
    {
      q: "Problemi con la connessione?",
      a: "Se hai problemi con la connessione prova a...",
    },
    { q: "Altra FAQ", a: "Se hai problemi con la connessione prova a..." },
  ],
  mobile_only: "Questa App è  progettata per dispositivi mobili",
  screen_locked: "Schermata bloccata",
  swipe_up: "Scorri verso l'alto per sbloccare",
  admin_title: "Pannello amministratore",
  admin_close: "Chiudi",
  admin_reset_soft: "Ricarica",
  admin_reset_hard: "Nuovo giro",
  end_title: "Il tour dei Fossi è terminato.",
  end_message: "Grazie per aver vissuto con noi questa esperienza sonora aumentata.",
  end_reset_hard: "Inizia nuovo giro"
};

export default it;
