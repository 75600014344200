import palette from "./palette";
import common from "./common.js";

//night theme
const styles = {
  ...common.styles,
  palette: {
    ...common.styles.palette,
    mode: "dark",
    background: {
      default: palette.dark_grey,
      rgb: palette.dark_grey_rgb,
      contrast: palette.white,
      unlockInstructions: 'transparent'
    },
    text: {
      unlockInstructions: palette.white
    }
  },
  components: {
    ...common.styles.components,
    MuiBackdrop: {
      styleOverrides: {
        root: { background: `${palette.dark_grey}F2` },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: { background: palette.dark_grey },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: { background: palette.white },
      },
    },
  },
};

const image_dir = "./night";

const night = { styles, image_dir };

export default night;
