const de = {
  lang: "Deutsch",
  select_lang: "Wählen Sie Ihre Sprache",
  day: "Tag",
  night: "Nacht",
  confirm: "Bestätigung",
  continue: "Weiter",
  yes_continue: "Ja, weiter",
  start: "Start",
  back: "Back",
  headset: "Tragen Sie das mitgelieferte Headset, um den Ton richtig zu hören",
  raise_volume: "Erhöhen Sie die Lautstärke des Smartphones",
  sound: "Hören Sie den Ton richtig?",
  livorno: '"L" wie Livorno',
  unlocking:
    "Verwenden Sie dieses Muster während Ihres Besuchs, um die App bei Bedarf freizuschalten",
  unlock: 'Entriegeln mit einem "L"',
  areyouready: "Sind Sie bereit für die Tour?",
  welcome: "WILLKOMMEN",
  beforestart:
    "Bevor das Boot abfährt, klicken Sie auf Start. Das Erlebnis startet dann von selbst, sodass Sie die Tour genießen können, ohne die App zu berühren.",
  map: "Karte",
  code: "FAQ",
  insert_code:
    "Insert the number corresponding to the tour stop as shown on the leaflet",
  faq: [
    {
      q: "Connection problems?",
      a: "If you are experiencing connection problems try to...",
    },
    {
      q: "Other FAQ",
      a: "If you are experiencing connection problems try to...",
    },
  ],
  mobile_only: "Diese App wurde für mobile Geräte entwickelt.",
  screen_locked: "Bildschirm gesperrt",
  swipe_up: "Zum Entsperren streichen",
  admin_title: "Administrationsmenü",
  admin_close: "Schließen",
  admin_reset_soft: "Reload",
  admin_reset_hard: "Neue tour",
  end_title: "Fossi-Tour ist beendet.",
  end_message: "Vielen Dank, dass Sie dieses erweiterte Audioerlebnis mit uns erleben.",
  end_reset_hard: "Neue tour"
};

export default de;
