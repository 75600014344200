import palette from "./palette";

const styles = {
  typography: {
    fontFamily: "Messina Sans",
    title: {
      fontWeight: "normal",
      fontSize: "6vw",
      lineHeight: "8vw",
    },
    pretitle: {
      fontWeight: "bold",
      fontSize: "4vw",
    },
    text: {
      fontSize: "4vw",
    },
  },
  palette: {
    primary: {
      main: palette.red,
    },
    secondary: {
      main: palette.grey,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          title: "h2",
          pretitle: "h5",
          subtitle: "h4",
          text: "p",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: { textAlign: "left" },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: { fontSize: "6vw" },
        input: { width: "100px" },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: "bold",
          fontSize: "4vw",
          padding: "3vw 15vw",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "3.6vw",
          fontWeight: "bold",
          padding: "3vw 0px",
        },
      },
    },
  },
  shadows: (function () {
    return Array.from(Array(25).keys()).map(() => "none");
  })(),
};

const common = { styles };

export default common;
