import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Tabs,
  Tab,
  Typography,
  Backdrop,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";

import MusicNoteIcon from '@material-ui/icons/MusicNote';

import Layout from "../components/Layout";
import String from "../components/String";
import ThemeSwitcher from "../components/ThemeSwitcher";
import Header from "../components/Header";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Footer from "../components/Footer";
import Map from "../components/Map";
import Lock from "../components/Lock";
import Soundwave from "../components/Sounwave";
import { useLanguage } from "../contexts/language";
import NoSleep from "nosleep.js";
import useVisibility from "../hooks/useVisibility";

import { Howl } from 'howler';

import { useExperience } from "../contexts/experience";

import welcomeSound from "../../assets/audio/music/-1.mp3";

const welcomeMusic = new Howl({
  src: [welcomeSound],
  format: "mp3",
  autoplay: false,
  html5: true,
  loop: true
});

const noSleep = new NoSleep();

const WELCOME_MUSIC_FADEOUT = 3000; // ms

export default function Tour() {

  const { active } = useExperience();

  const [start, setStart] = useState(false);

  const [welcomeEnded, setWelcomeEnded] = useState(false);

  const [tab, setTab] = useState("map");

  useEffect(() => {
    if (active && active.id) {
      welcomeMusic.fade(1, 0, WELCOME_MUSIC_FADEOUT);
    }
  }, [active]);

  useEffect(() => {

    welcomeMusic.once('fade', () => {
      welcomeMusic.stop();
      welcomeMusic.unload();
      setWelcomeEnded(true);
    });

    return () => noSleep.disable();
  }, []);

  return (
    <Layout
      full
      top={<Header />}
      bottom={
        <Footer>
          <ThemeSwitcher />
        </Footer>
      }
    >
      <Overlay start={start} setStart={setStart} setWelcomeEnded={setWelcomeEnded} welcomeEnded={welcomeEnded} />

      <Layout
        full
        top={
          <AppBar position="static" color="transparent">
            <Tabs
              value={tab}
              onChange={(e, t) => setTab(t)}
              variant="fullWidth"
              textColor="inherit"
            >
              <Tab value={"map"} label={<String s="map" />} />
              {/* <Tab value={"code"} label={<String s="code" />} /> */}
            </Tabs>
          </AppBar>
        }
      >
        {tab === "map" && <MapTab />}
        {/* {tab === "code" && <CodeTab />} */}
      </Layout>
      {start && <Lock />}
    </Layout>
  );
}

function Overlay({ start, setStart, setWelcomeEnded, welcomeEnded }) {

  const { getGeoXpCookie } = useExperience();

  return (
    <Backdrop style={{ position: "absolute", zIndex: 9 }} open={(!start || !welcomeEnded)}>
      <Layout justify="center">
        <Box textAlign="center" ml={4} mr={4}>
          {
            !start &&
            <>
              <Typography variant="title" mb={2}>
                <String s="areyouready" />
              </Typography>
              <Typography variant="text" mb={3}>
                <String s="beforestart" />
              </Typography>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  noSleep.enable();
                  const geoXpCookie = getGeoXpCookie('exp1music');
                  setStart(true);
                  if (geoXpCookie) {
                    setWelcomeEnded(true);
                  } else {
                    welcomeMusic.play();
                  }
                }}
              >
                <MusicNoteIcon />
                <String s="start" />

              </Button>
            </>
          }
          {
            start &&
            <>
              <Typography variant="title" mb={2}>
                <String s="welcome" />
              </Typography>

              <Soundwave />
            </>
          }
        </Box>
      </Layout>
    </Backdrop>
  );
}

function MapTab() {
  return (
    <Layout full>
      <Map />
    </Layout>
  );
}
function CodeTab() {
  const [code, setCode] = useState();
  const { content } = useLanguage();
  const faq = content("faq");
  return (
    <Layout justify="start" full>
      <Box maxWidth={"85vw"} style={{ paddingTop: 30 }}>
        {faq.map((f, fi) => (
          <Accordion key={fi}>
            <AccordionSummary expandIcon={<ExpandMore />} id={"faq_" + fi}>
              <Typography>{f.q}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">{f.a}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Layout>
  );
}
