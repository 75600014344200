import music from "./music";

const selectedLangAudios = music;

const audio = {
  sounds: [
    {
      id: "a0a",
      label: selectedLangAudios.audio0.label,
      url: selectedLangAudios.audio0.url,
      format: "mp3",
    },
    {
      id: "a01",
      label: selectedLangAudios.audio1.label,
      url: selectedLangAudios.audio1.url,
      format: "mp3",
    },
    {
      id: "a02",
      label: selectedLangAudios.audio2.label,
      url: selectedLangAudios.audio2.url,
      format: "mp3",
    },
    {
      id: "a03",
      label: selectedLangAudios.audio3.label,
      url: selectedLangAudios.audio3.url,
      format: "mp3",
    },
    {
      id: "a04",
      label: selectedLangAudios.audio4.label,
      url: selectedLangAudios.audio4.url,
      format: "mp3",
    },
    {
      id: "a05",
      label: selectedLangAudios.audio5.label,
      url: selectedLangAudios.audio5.url,
      format: "mp3",
    },
    {
      id: "a06",
      label: selectedLangAudios.audio6.label,
      url: selectedLangAudios.audio6.url,
      format: "mp3",
    },
    {
      id: "a07",
      label: selectedLangAudios.audio7.label,
      url: selectedLangAudios.audio7.url,
      format: "mp3",
    },
    {
      id: "a08",
      label: selectedLangAudios.audio8.label,
      url: selectedLangAudios.audio8.url,
      format: "mp3",
    },
    {
      id: "a09",
      label: selectedLangAudios.audio9.label,
      url: selectedLangAudios.audio9.url,
      format: "mp3",
    },
    {
      id: "a10",
      label: selectedLangAudios.audio10.label,
      url: selectedLangAudios.audio10.url,
      format: "mp3",
    },
    {
      id: "a11",
      label: selectedLangAudios.audio11.label,
      url: selectedLangAudios.audio11.url,
      format: "mp3",
    },
    {
      id: "a12",
      label: selectedLangAudios.audio12.label,
      url: selectedLangAudios.audio12.url,
      format: "mp3",
    },
    {
      id: "a0b",
      label: selectedLangAudios.audio0_b.label,
      url: selectedLangAudios.audio0_b.url,
      format: "mp3",
    },
    {
      id: "a04b",
      label: selectedLangAudios.audio4_b.label,
      url: selectedLangAudios.audio4_b.url,
      format: "mp3",
    },
    {
      id: "a12b",
      label: selectedLangAudios.audio12_b.label,
      url: selectedLangAudios.audio12_b.url,
      format: "mp3",
    },
  ],
  options: {
    fadeInTime: 3000,
    fadeOutTime: 3000
  }
};

export default audio;
