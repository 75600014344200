const fr = {
  lang: "Français",
  select_lang: "Choisissez votre langue",
  day: "Jour",
  night: "Nuit",
  confirm: "Confirmer",
  continue: "Continuer",
  yes_continue: "Oui, continuez",
  start: "Start",
  back: "Retour",
  headset: "Portez les écouteurs fournis pour entendre correctement le son",
  raise_volume: "Augmenter le volume du smartphone",
  sound: "Entendez-vous correctement l'audio?",
  livorno: '"L" comme Livorno',
  unlocking:
    "Pendant la visite, si vous souhaitez parcourir l'application, utilisez cette séquence pour la déverrouiller",
  unlock: 'Déverrouiller avec un "L"',
  areyouready: "Prêt à commencer la visite?",
  welcome: "BIENVENUE",
  beforestart:
    "Avant le départ du bateau, cliquez sur Start. L'expérience démarrera toute seule, ce qui vous permettra de profiter de la visite sans toucher à l'application.",
  map: "Carte",
  code: "FAQ",
  insert_code:
    "Insert the number corresponding to the tour stop as shown on the leaflet",
  faq: [
    {
      q: "Connection problems?",
      a: "If you are experiencing connection problems try to...",
    },
    {
      q: "Other FAQ",
      a: "If you are experiencing connection problems try to...",
    },
  ],
  mobile_only: "Cette application a été conçue pour les appareils mobiles.",
  screen_locked: "Écran verrouillé",
  swipe_up: "Glisser vers le haut pour déverrouiller",
  admin_title: "Panneau d'administration",
  admin_close: "Fermer",
  admin_reset_soft: "Rafraîchir",
  admin_reset_hard: "Nouvelle tournée",
  end_title: "La visite de Fossi est terminée.",
  end_message: "Merci de vivre avec nous cette expérience audio augmentée.",
  end_reset_hard: "Nouvelle tournée"
};

export default fr;
