const es = {
  lang: "Español",
  select_lang: "Elija su idioma",
  day: "Día",
  night: "Noche",
  confirm: "Confirmar",
  continue: "Continuar",
  yes_continue: "Sí, continúa",
  start: "Start",
  back: "Atrás",
  headset: "Usa los auriculares suministrados para escuchar el sonido correctamente.",
  raise_volume: "Aumenta el volumen del teléfono",
  sound: "¿Estás escuchando el audio correctamente?",
  livorno: '"L" como Livorno',
  unlocking:
    "Si necesitas utilizar la aplicación durante la visita, desbloquéala con este código",
  unlock: 'Desbloquear con una "L"',
  areyouready: "¿Preparado para empezar el recorrido?",
  welcome: "BIENVENIDO",
  beforestart:
    "Antes de que el barco parta, haz clic en Start. La aplicación iniciará automaticamente, para que puedas disfrutar del recorrido sin tener que interactuar con el dispositivo.",
  map: "Mapa",
  code: "FAQ",
  insert_code:
    "Insert the number corresponding to the tour stop as shown on the leaflet",
  faq: [
    {
      q: "Connection problems?",
      a: "If you are experiencing connection problems try to...",
    },
    {
      q: "Other FAQ",
      a: "If you are experiencing connection problems try to...",
    },
  ],
  mobile_only: "Esta aplicación fue diseñada para dispositivos móviles.",
  screen_locked: "Pantalla bloqueada",
  swipe_up: "Deslizar hacia arriba para desbloquear",
  admin_title: "Panel de administrador",
  admin_close: "Cerrar",
  admin_reset_soft: "Recarga",
  admin_reset_hard: "Nueva gira",
  end_title: "La gira ha terminado.",
  end_message: "Gracias por vivir con nosotros esta experiencia de audio aumentada.",
  end_reset_hard: "Nueva gira"
};

export default es;
