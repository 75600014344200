const en = {
  lang: "English",
  select_lang: "Choose your language",
  day: "Day",
  night: "Night",
  confirm: "Confirm",
  continue: "Continue",
  yes_continue: "Yes, continue",
  start: "Start",
  back: "Back",
  headset: "Please use the provided headset for a better experience",
  raise_volume: "Turn up your device's volume",
  sound: "Can you hear the sound correctly?",
  livorno: '"L" as Livorno',
  unlocking:
    "If you need to use the app during the visit, unlock it using this code",
  unlock: 'Unlock with an "L"',
  areyouready: "Ready to begin the tour?",
  welcome: "WELCOME",
  beforestart:
    "Before the boat departs, click on Start. The experience will start on its own, so you can enjoy the tour without reopening the app.",
  map: "Map",
  code: "FAQ",
  insert_code:
    "Insert the number corresponding to the tour stop as shown on the leaflet",
  faq: [
    {
      q: "Connection problems?",
      a: "If you are experiencing connection problems try to...",
    },
    {
      q: "Other FAQ",
      a: "If you are experiencing connection problems try to...",
    },
  ],
  mobile_only: "This App was designed for mobile devices.",
  screen_locked: "Screen locked",
  swipe_up: "Swipe up to unlock",
  admin_title: "Admin panel",
  admin_close: "Close",
  admin_reset_soft: "Refresh",
  admin_reset_hard: "New tour",
  end_title: "Fossi tour is finished.",
  end_message: "Thanks for living with us this augmented audio experience.",
  end_reset_hard: "New tour"
};

export default en;
