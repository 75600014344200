import palette from "./palette";
import common from "./common.js";

const styles = {
  ...common.styles,
  palette: {
    ...common.styles.palette,
    mode: "light",
    background: {
      default: palette.white,
      rgb: palette.white_rgb,
      contrast: palette.dark_grey,
      unlockInstructions: `${palette.dark_grey}96`
    },
    text: {
      unlockInstructions: palette.white
    }
  },
  components: {
    ...common.styles.components,
    MuiBackdrop: {
      styleOverrides: {
        root: { background: `${palette.white}CC` },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: { background: palette.white },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: { background: palette.dark_grey },
      },
    },
  },
};

const image_dir = "./day";

const day = { styles, image_dir };

export default day;
